<template>
  <div id="user-add-tab-info">

    <!-- Content Row -->
    <div class="vx-row">
      <div class="vx-col md:w-1/2 w-full">

        <div class="vx-col w-full">
          <div class="flex items-end px-3">
            <feather-icon svgClasses="w-6 h-6" icon="LockIcon" class="mr-2" />
            <span class="font-medium text-lg leading-none">Datos personales</span>
          </div>
          <vs-divider />
        </div>

        <div class="mt-4">
          <label>Rol</label>
          <div class="flex flex-wrap mt-3 mb-6">
            <vs-radio v-model="data_local.profile_type" vs-value="3" class="mr-4">Admin Cafetería</vs-radio>
            <vs-radio v-model="data_local.profile_type" vs-value="6" class="mr-4">Admin Escuela</vs-radio>
            <vs-radio v-model="data_local.profile_type" vs-value="7" class="mr-4">Admin Tienda</vs-radio>
          </div>
        </div>

        <vs-input class="w-full mt-4" label="Nombre" autocomplete="off" v-model="data_local.name" v-validate="'required|alpha_spaces'" name="name" autofocus />
        <span class="text-danger text-sm"  v-show="errors.has('name')">{{ errors.first('name') }}</span>

        <vs-input class="w-full mt-4" label="Apellido" autocomplete="off" v-model="data_local.last_name" v-validate="'required|alpha_spaces'" name="last_name" />
        <span class="text-danger text-sm"  v-show="errors.has('last_name')">{{ errors.first('last_name') }}</span>

        <div class="mt-4 flex flex-wrap">
          <div class="w-1/2">
            <vs-input class="w-full" label="Email" autocomplete="off" v-model="data_local.email" type="email" v-validate="'required|email'" name="email" />
            <span class="text-danger text-sm"  v-show="errors.has('email')">{{ errors.first('email') }}</span>
          </div>
          <div class="w-1/2">
            <vs-input label="Teléfono" autocomplete="off" class="w-full pl-4" v-model="data_local.phone_number" name="phone_number"/>
            <span class="text-danger text-sm"  v-show="errors.has('phone_number')">{{ errors.first('phone_number') }}</span>
          </div>
        </div>

        <div class="mt-4 flex flex-wrap">
          <label class="vs-input--label w-full">Documento de identidad</label>
          <!-- <v-select :clearable="false" :options="cardTypeOptions" @input="updateCardType($event)" v-model="data_local.card_type_id" v-validate="'required'" class="w-1/4" name="card_type_id" placeholder="Tipo" :dir="$vs.rtl ? 'rtl' : 'ltr'" /> -->
          <div class="w-1/2">
            <v-select browserAutocomplete="off" autocomplete="off" v-model="data_local.card_type_id" :reduce="card_type_id => card_type_id.value" :options="cardTypeOptions" :clearable="false" v-validate="'required'" name="card_type_id" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
            <!-- <vs-select :clearable="false"  v-validate="'required'" v-model="data_local.card_type_id" name="card_type_id" placeholder="Tipo" :dir="$vs.rtl ? 'rtl' : 'ltr'">
              <vs-select-item :key="item.value" :value="item.value" :text="item.text" v-for="item in cardTypeOptions" />
            </vs-select> -->
          </div>
          <span class="text-danger text-sm"  v-show="errors.has('card_type_id')">{{ errors.first('card_type_id') }}</span>
          <div class="w-1/2 pl-4">
            <vs-input class="w-full" v-model="data_local.card_id" autocomplete="off" v-validate="'required|max:15'" name="card_id" placeholder="Número" data-vv-as="Número de Documento"/>
            <span class="text-danger text-sm"  v-show="errors.has('card_id')">{{ errors.first('card_id') }}</span>
          </div>
        </div>

        <div class="mt-4">
          <label>Fecha de Nacimiento</label>
          <div class="flex flex-wrap">
            <datepicker :disabledDates="disabledDates" :useUtc="true" :openDate="openDate" :format="dateFormat" v-model="birthdayLocal" autocomplete="off" v-validate="'required'" name="birthday_date"></datepicker>
            <span class="text-danger text-sm"  v-show="errors.has('birthday_date')">{{ errors.first('birthday_date') }}</span>
          </div>
        </div>

        <div class="mt-4 flex flex-wrap">
          <div class="w-1/2">
            <vs-input class="w-full" label="Dirección" v-model="data_local.address" autocomplete="off" name="address" v-validate="'required'" data-vv-as="Dirección"/>
            <span class="text-danger text-sm"  v-show="errors.has('address')">{{ errors.first('address') }}</span>
          </div>
          <div class="w-1/4 pl-4">
            <vs-input class="w-full" label="Ciudad" v-model="data_local.city" autocomplete="off" name="city" v-validate="'required'" data-vv-as="Ciudad"/>
            <span class="text-danger text-sm"  v-show="errors.has('city')">{{ errors.first('city') }}</span>
          </div>
          <div class="w-1/4 pl-4">
            <vs-input class="w-full" label="Código Postal" v-model="data_local.zip_code" autocomplete="off" name="zip_code" v-validate="'required'" data-vv-as="Código Postal"/>
            <span class="text-danger text-sm"  v-show="errors.has('zip_code')">{{ errors.first('zip_code') }}</span>
          </div>
        </div>

        <div class="mt-4 flex flex-wrap">
          <div class="w-1/2">
            <vs-input class="w-full" label="Empresa" v-model="data_local.company_name" autocomplete="off" name="company_name" v-validate="'required'" data-vv-as="Empresa"/>
            <span class="text-danger text-sm"  v-show="errors.has('company_name')">{{ errors.first('company_name') }}</span>
          </div>
          <div class="w-1/2 pl-4">
            <vs-input class="w-full" label="Nro. de Identificación" v-model="data_local.company_code" autocomplete="off" name="company_code" v-validate="'required'" data-vv-as="Nro. de Identificación"/>
            <span class="text-danger text-sm"  v-show="errors.has('company_code')">{{ errors.first('company_code') }}</span>
          </div>
        </div>

      </div>

      <div v-if="data_local.profile_type == 3" class="vx-col md:w-1/2 w-full">

        <div class="vx-col w-full">
          <div class="flex items-end px-3">
            <feather-icon svgClasses="w-6 h-6" icon="LockIcon" class="mr-2" />
            <span class="font-medium text-lg leading-none">Cuenta Bancaria</span>
          </div>
          <vs-divider />
        </div>

        <div class="mt-4">
          <label>Tipo de cuenta</label>
          <div class="flex flex-wrap mt-3 mb-6">
            <vs-radio v-model="data_local.account_type" vs-value="1" class="mr-4">Cuenta Corriente</vs-radio>
            <vs-radio v-model="data_local.account_type" vs-value="0" class="mr-4">Caja de Ahorro</vs-radio>
          </div>
        </div>

        <div class="mt-4">
          <vs-input class="w-full mt-4" label="Nombre de la Cuenta" v-model="data_local.account_name" autocomplete="off" v-validate="'required'" name="account_name" data-vv-as="Nombre de la Cuenta"/>
          <span class="text-danger text-sm"  v-show="errors.has('account_name')">{{ errors.first('account_name') }}</span>
        </div>

        <div class="mt-4">
          <vs-input class="w-full mt-4" label="Banco" v-model="data_local.bank_name" autocomplete="off" v-validate="'required'" name="bank_name" data-vv-as="Banco"/>
          <span class="text-danger text-sm"  v-show="errors.has('bank_name')">{{ errors.first('bank_name') }}</span>
        </div>

        <div class="mt-4">
          <vs-input class="w-full mt-4" label="Número de cuenta" v-model="data_local.bank_account" autocomplete="off" v-validate="'required'" name="bank_account" data-vv-as="Número de cuenta"/>
          <span class="text-danger text-sm"  v-show="errors.has('bank_account')">{{ errors.first('bank_account') }}</span>
        </div>

        <!-- <div class="mt-4">
          <label>% Comisión Tiptap</label>
          <div class="flex flex-wrap">
            <vs-input-number v-model="data_local.tiptap_commission" :step="0.5" name="tiptap_commission"/>
            <span class="text-danger text-sm"  v-show="errors.has('tiptap_commission')">{{ errors.first('tiptap_commission') }}</span>
          </div>
        </div> -->


        <!-- <div class="mt-4">
          <label class="vs-input--label">Rol</label>
          <v-select :clearable="false" v-model="data_local.profile_type" :options="roleOptions" :reduce="profile_type => profile_type.label" v-validate="'required'" :value="{ label: 'Admin',  value: '3' }" name="profile_type" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
          <span class="text-danger text-sm"  v-show="errors.has('profile_type')">{{ errors.first('profile_type') }}</span>
        </div> -->

        <!-- <div class="mt-4">
            <vs-input class="w-full mt-4" label="Empresa" v-model="data_local.company" name="company" />
            <span class="text-danger text-sm"  v-show="errors.has('company')">{{ errors.first('company') }}</span>
        </div> -->

        <!-- <div class="mt-4">
          <label class="vs-input--label">Estado</label>
          <v-select :clearable="false" :options="statusOptions" @input="updateStatus($event)" v-validate="'required'" name="status" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
          <span class="text-danger text-sm"  v-show="errors.has('status')">{{ errors.first('status') }}</span>
        </div> -->

        <!-- <vs-input class="w-full mt-4" label="Company" v-model="data_local.company" v-validate="'alpha_spaces'" name="company" />
        <span class="text-danger text-sm"  v-show="errors.has('company')">{{ errors.first('company') }}</span> -->

      </div>

    </div>

    <!-- Popup User Created Info -->
    <!-- cancel-text="Enviar Datos" -->
    <!-- @cancel="sendUserData" -->
    <vs-prompt
      type="confirm"
      title="Usuario Creado"
      accept-text="Copiar"
      @accept="copyToClipboard"
      @cancel="closePrompt"
      cancel-text="Cerrar"
      :active.sync="showUserCreatedPrompt">
      <div>
        <span>Se han enviado los datos de acceso por email a <b>{{data_local.email}}</b></span>
        <div class="op-block mt-4 mb-2 w-full">
          <!-- <p class="mt-1 mb-1"><b>Usuario:</b> {{ generatedUsername == "" ? "" : generatedUsername }}</p> -->
          <p class="mt-1 mb-1"><b>Email:</b> {{ data_local.email }}</p>
          <p class="mt-1 mb-1"><b>Password:</b> {{ generatedPassword == "" ? "" : generatedPassword }}</p>
        </div>
        <!-- <div class="op-block mt-4 mb-2 w-full"></div> -->
      </div>
    </vs-prompt>

    <!-- Save & Reset Button -->
    <div class="vx-row mt-12">
      <div class="vx-col w-full mt-12">
        <div class="mt-12 flex flex-wrap items-center justify-end">
          <vs-button class="ml-auto mt-12" @click="save_changes" id="saveButton" :disabled="!validateForm">Guardar</vs-button>
          <vs-button class="ml-4 mt-12" type="border" color="warning" @click="reset_data">Cancelar</vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select';

export default {
  components: {
    Datepicker,
    vSelect
  },
  props: {
    // data: {
    //   type: Object,
    //   required: false,
    // },
  },
  data() {
    return {

      data_local: {
        name: "",
        last_name: "",
        email: "",
        profile_type: "3",
        status: "",
        password: "",
        card_id: "",
        birthday_date: "",
        region: "1",
        id_institution: "",
        user: "",
        company_name: "",
        company_code: "",
        //tiptap_commission: 0,
      },

      bank: "",

      showUserCreatedPrompt: false,

      generatedUsername: "",
      generatedPassword: "",

      statusOptions: [
        { label: "Activo",  value: "1" },
        { label: "Inactivo",  value: "0" },
      ],
      cardTypeOptions: [
        { label: "CEDULA",  value: 1 },
        { label: "PASAPORTE",  value: 2 },
      ],
      bankOptions: [
        { label: "Credicorp Bank Panamá",  value: "1" },
      ],

      backgroundLoading: 'primary',
      colorLoading: '#fafafa',
      loading : false,

      disabledDates: {
        // to: new Date(new Date().getFullYear(), new Date().getMonth()-2, 5), // Disable all dates up to specific date
        from: new Date(new Date().getFullYear()-16, new Date().getMonth(), new Date().getDay()), // Disable all dates after specific date
        // daysOfMonth: [8, 11, 17], // Disable Specific days
      },
      openDate: new Date(new Date().getFullYear()-18, new Date().getMonth(), new Date().getDay()),
      dateFormat: "dd-MM-yyyy",
    }
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.data_local.profile_type && this.data_local.name && this.data_local.last_name && this.data_local.email && this.data_local.card_type_id && this.data_local.card_id && !this.loading
    },
    birthdayLocal: {
      get() {
        // console.log('computed get', this.data_local.birthday_date.split("T")[0])
        return this.isDate(this.data_local.birthday_date) ? this.data_local.birthday_date.toISOString().split("T")[0] : this.data_local.birthday_date.split("T")[0]
      },
      set(val) {
        // console.log('computed set val', val)
        this.data_local.birthday_date = val.toISOString().split("T")[0]
      }
    },
  },
  methods: {
    capitalize(str) {
      return str.slice(0,1).toUpperCase() + str.slice(1,str.length)
    },
    copyToClipboard() {
      this.$copyText("Email: " + this.data_local.email + " | Password: " + this.generatedPassword)
        .then(() => {
          this.showNotify("success", "Correcto!", "Se ha copiado la información.")
          this.closePrompt()
        })
        .catch(error => this.showNotify("error", "Error", "No se pudo copiar la información. " + error))
    },
    save_changes() {

      this.data_local.name = this.data_local.name.trim().replace(/ +(?= )/g,'')
      this.data_local.last_name = this.data_local.last_name.trim().replace(/ +(?= )/g,'')
      delete this.data_local.status

      if(!this.validateForm) return

      this.disableSaveButton()

      this.$store.dispatch("userManagement/addUser", this.data_local)
      .then(response => {
        if (response.status) {
          this.generatedUsername = response.data.account.username
          this.generatedPassword = response.data.account.password
          this.openUserCreatedDialog()
        } else {
          this.showAddError(response.msg)
        }
      })
      .catch(error => {
        this.showAddError(error)
      })

    },
    reset_data() {
      // this.data_local = JSON.parse(JSON.stringify(this.data))
      this.$router.push("/users").catch((error) => {console.log(error)})
    },
    update_avatar() {
      // You can update avatar Here
      // For reference you can check dataList example
      // We haven't integrated it here, because data isn't saved in DB
    },
    openUserCreatedDialog() {
      this.showUserCreatedPrompt = true
      // this.$vs.dialog({
      //   type: 'alert',
      //   color: 'success',
      //   title: `Usuario Creado`,
      //   text: 'Usuario creado correctamente. Los datos de acceso son: ',
      //   accept: this.showAddSuccess
      // })
    },
    showNotify(type,title,text) {
      this.$vs.notify({
        color: type || 'success',
        title: title || 'Correcto!',
        text: text || '',
        time: 8000,
        position: 'top-center',
        iconPack: 'feather',
        icon:'icon-check',
      })
    },
    showAddError(msg) {
      this.$vs.notify({
        color: 'danger',
        title: 'Error',
        text: msg || 'No se pudo agregar el usuario.',
        time: 8000,
        position: 'top-center',
        iconPack: 'feather',
        icon:'icon-alert-circle',
      })
      this.enableSaveButton()
    },
    updateCardType(event) {
      this.data_local.card_type = event.value
    },
    updateStatus(event) {
      this.data_local.status = event.value
    },
    disableSaveButton() {
      this.loading = true
      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: "#saveButton",
        scale: 0.45
      })
    },
    enableSaveButton() {
      this.loading = false
      this.$vs.loading.close("#saveButton > .con-vs-loading")
    },
    closePrompt() {
      this.showUserCreatedPrompt = false
      setTimeout(() =>{
        this.$router.push("/users").catch(error => { console.log(error)})
      }, 100)
    },
    sendUserData() {
      this.showNotify(null,"Nuevo Usuario", "Credenciales enviadas por email a " + this.data_local.email)
      this.closePrompt()
    },
    isDate(val) {
      return val instanceof Date && !isNaN(val.valueOf());
    },
  },
}
</script>

<style lang="css" scoped>
  .mt-4 > label {
    font-size: 12px;
  }
</style>
