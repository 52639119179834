<template>
  <div id="user-add-tab-info">

    <!-- Content Row -->
    <div class="vx-row">
      <div class="vx-col md:w-1/2 w-full">

        <vs-input class="w-full mt-4" label="Nombre" v-model="data_local.name" v-validate="'required|alpha_spaces'" name="name" autofocus />
        <span class="text-danger text-sm"  v-show="errors.has('name')">{{ errors.first('name') }}</span>

        <vs-input class="w-full mt-4" label="Apellido" v-model="data_local.last_name" v-validate="'required|alpha_spaces'" name="last_name" />
        <span class="text-danger text-sm"  v-show="errors.has('last_name')">{{ errors.first('last_name') }}</span>

        <div class="mt-4 flex flex-wrap">
          <div class="w-1/2">
            <vs-input class="w-full" label="Email" v-model="data_local.email" type="email" v-validate="'required|email'" name="email" />
            <span class="text-danger text-sm"  v-show="errors.has('email')">{{ errors.first('email') }}</span>
          </div>
          <div class="w-1/2">
            <vs-input label="Teléfono" class="w-full pl-4" v-model="data_local.phone_number" name="phone_number" placeholder="Número de teléfono"/>
            <span class="text-danger text-sm"  v-show="errors.has('phone_number')">{{ errors.first('phone_number') }}</span>
          </div>
        </div>

        <div class="mt-4 flex flex-wrap">
          <label class="vs-input--label w-full">Documento de identidad</label>
          <!-- <v-select :clearable="false" :options="cardTypeOptions" @input="updateCardType($event)" v-validate="'required'" class="w-1/4" name="card_type_id" placeholder="Tipo" :dir="$vs.rtl ? 'rtl' : 'ltr'" /> -->
          <div class="w-1/2">
            <v-select browserAutocomplete="off" autocomplete="off" v-model="data_local.card_type_id" :reduce="card_type_id => card_type_id.value" :options="cardTypeOptions" :clearable="false" v-validate="'required'" name="card_type_id" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
            <!-- <vs-select :clearable="false"  v-validate="'required'" v-model="data_local.card_type_id" name="card_type_id" placeholder="Tipo" :dir="$vs.rtl ? 'rtl' : 'ltr'">
              <vs-select-item :key="item.value" :value="item.value" :text="item.text" v-for="item in cardTypeOptions" />
            </vs-select> -->
          </div>
          <span class="text-danger text-sm"  v-show="errors.has('card_type_id')">{{ errors.first('card_type_id') }}</span>
          <div class="w-1/2 pl-4">
            <vs-input class="w-full" v-model="data_local.card_id" v-validate="'required|max:15'" name="card_id" placeholder="Número"/>
            <span class="text-danger text-sm"  v-show="errors.has('card_id')">{{ errors.first('card_id') }}</span>
          </div>
        </div>
      </div>

      <div class="vx-col md:w-1/2 w-full">
        <div class="mt-4">
          <label>Fecha de nacimiento</label>
          <div class="flex flex-wrap">
            <datepicker :disabledDates="disabledDates" :openDate="openDate" :format="dateFormat" v-model="data_local.birthday_date" v-validate="'required'" name="birthday_date"></datepicker>
            <span class="text-danger text-sm"  v-show="errors.has('birthday_date')">{{ errors.first('birthday_date') }}</span>
          </div>
        </div>

        <div class="mt-4 flex flex-wrap">
          <vs-input class="w-1/2" label="Dirección" v-model="data_local.address" name="address" />
          <span class="text-danger text-sm"  v-show="errors.has('address')">{{ errors.first('address') }}</span>
          <vs-input class="w-1/4 pl-4" label="Ciudad" v-model="data_local.city" name="city" placeholder="Ciudad"/>
          <span class="text-danger text-sm"  v-show="errors.has('city')">{{ errors.first('city') }}</span>
          <vs-input class="w-1/4 pl-4" label="Zip Code" v-model="data_local.zip_code" name="zip_code" placeholder="ZipCode"/>
          <span class="text-danger text-sm"  v-show="errors.has('zip_code')">{{ errors.first('zip_code') }}</span>
        </div>

        <div class="mt-4">
          <label class="vs-input--label">Rol</label>
          <v-select browserAutocomplete="off" autocomplete="off" v-model="data_local.profile_type" :reduce="profile_type => profile_type.value" :options="roleOptions" :clearable="false" v-validate="'required'" name="profile_type" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
          <!-- <v-select :clearable="false" :options="roleOptions" @input="updateProfileType($event)" v-validate="'required'" name="profile_type" :dir="$vs.rtl ? 'rtl' : 'ltr'" /> -->
          <!-- <vs-select :clearable="false" v-model="data_local.profile_type" :options="roleOptions" placeholder="Rol" v-validate="'required'" name="profile_type" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <vs-select-item :key="item.value" :value="item.value" :text="item.text" v-for="item in roleOptions" />
          </vs-select> -->
          <span class="text-danger text-sm"  v-show="errors.has('profile_type')">{{ errors.first('profile_type') }}</span>
        </div>

        <!-- <div class="mt-4">
          <label class="vs-input--label">Estado</label>
          <v-select :clearable="false" :options="statusOptions" @input="updateStatus($event)" v-validate="'required'" name="status" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
          <span class="text-danger text-sm"  v-show="errors.has('status')">{{ errors.first('status') }}</span>
        </div> -->

        <!-- <vs-input class="w-full mt-4" label="Company" v-model="data_local.company" v-validate="'alpha_spaces'" name="company" />
        <span class="text-danger text-sm"  v-show="errors.has('company')">{{ errors.first('company') }}</span> -->

      </div>
    </div>

    <!-- Permissions -->
    <!-- <vx-card class="mt-base" no-shadow card-border>

      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="flex items-end px-3">
            <feather-icon svgClasses="w-6 h-6" icon="LockIcon" class="mr-2" />
            <span class="font-medium text-lg leading-none">Permissions</span>
          </div>
          <vs-divider />
        </div>
      </div>

      <div class="block overflow-x-auto">
        <table class="w-full">
          <tr> -->
            <!--
              You can also use `Object.keys(Object.values(data_local.permissions)[0])` this logic if you consider,
              our data structure. You just have to loop over above variable to get table headers.
              Below we made it simple. So, everyone can understand.
             -->
            <!-- <th class="font-semibold text-base text-left px-3 py-2" v-for="heading in ['Module', 'Read', 'Write', 'Create', 'Delete']" :key="heading">{{ heading }}</th>
          </tr>

          <tr v-for="(val, name) in data_local.permissions" :key="name">
            <td class="px-3 py-2">{{ name }}</td>
            <td v-for="(permission, name) in val" class="px-3 py-2" :key="name+permission">
              <vs-checkbox v-model="val[name]" />
            </td>
          </tr>
        </table>
      </div>

    </vx-card> -->

    <!-- Popup User Created Info -->
    <!-- cancel-text="Enviar Datos" -->
    <!-- @cancel="sendUserData" -->
    <vs-prompt
      type="confirm"
      title="Usuario Creado"
      accept-text="Copiar"
      @accept="copyToClipboard"
      @cancel="closePrompt"
      cancel-text="Cerrar"
      :active.sync="showUserCreatedPrompt">
      <div>
        <span>Se han enviado los datos de acceso por email a <b>{{data_local.email}}</b></span>
        <div class="op-block mt-4 mb-2 w-full">
          <!-- <p class="mt-1 mb-1"><b>Usuario:</b> {{ generatedUsername == "" ? "" : generatedUsername }}</p> -->
          <p class="mt-1 mb-1"><b>Email:</b> {{ data_local.email }}</p>
          <p class="mt-1 mb-1"><b>Password:</b> {{ generatedPassword == "" ? "" : generatedPassword }}</p>
        </div>
        <!-- <div class="op-block mt-4 mb-2 w-full"></div> -->
      </div>
    </vs-prompt>

    <!-- Save & Reset Button -->
    <div class="vx-row mt-12">
      <div class="vx-col w-full mt-12">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <vs-button class="ml-auto mt-12" @click="save_changes" id="saveButton" :disabled="!validateForm">Guardar</vs-button>
          <vs-button class="ml-4 mt-12" type="border" color="warning" @click="reset_data">Cancelar</vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'

export default {
  components: {
    Datepicker,
    vSelect
  },
  props: {
    data: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {

      data_local: JSON.parse(JSON.stringify(this.data)),
      showUserCreatedPrompt: false,

      generatedUsername: "",
      generatedPassword: "",

      cardTypeOptions: [
        // vs-select
        // { text: "CEDULA",  value: "1" },
        // { text: "PASAPORTE",  value: "2" },
        // v-select
        { label: "CEDULA",  value: 1 },
        { label: "PASAPORTE",  value: 2 },
      ],
      roleOptions: [
        // vs-select
        // { text: "Gerente",  value: "4" },
        // { text: "Cajero",  value: "5" },

        // v-select
        { label: "Gerente", value: 4 },
        { label: "Cajero",  value: 5 },
      ],

      backgroundLoading: 'primary',
      colorLoading: '#fafafa',
      loading : false,

      disabledDates: {
        // to: new Date(new Date().getFullYear()-18, new Date().getMonth(), new Date().getDay()), // Disable all dates up to specific date
        from: new Date(new Date().getFullYear()-16, new Date().getMonth(), new Date().getDay()), // Disable all dates after specific date
        // daysOfMonth: [8, 11, 17], // Disable Specific days
      },
      openDate: new Date(new Date().getFullYear()-18, new Date().getMonth(), new Date().getDay()),
      dateFormat: "dd-MM-yyyy",
    }
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.data_local.profile_type && this.data_local.name && this.data_local.last_name && this.data_local.email && this.data_local.card_type_id && this.data_local.card_id && !this.loading
    }
  },
  methods: {
    capitalize(str) {
      return str.slice(0,1).toUpperCase() + str.slice(1,str.length)
    },
    isDate(val) {
      return val instanceof Date && !isNaN(val.valueOf())
    },
    save_changes() {
      
      this.data_local.name = this.data_local.name.trim().replace(/ +(?= )/g,'');
      this.data_local.last_name = this.data_local.last_name.trim().replace(/ +(?= )/g,'');
      this.data_local.birthday_date = this.isDate(this.data_local.birthday_date)?this.data_local.birthday_date.toISOString().split('T')[0] : '';
      delete this.data_local.status

      if(!this.validateForm) return
      
      this.disableSaveButton()
      
      this.$store.dispatch("userManagement/addUser", this.data_local)
      .then(response => {
        if (response.status) {
          this.generatedUsername = response.data.account.username
          this.generatedPassword = response.data.account.password
          this.openUserCreatedDialog()
        } else {
          this.showAddError(response.msg)
        }
      })
      .catch(error => { 
        this.showAddError(error)
      })

    },
    reset_data() {
      this.data_local = JSON.parse(JSON.stringify(this.data))
      this.$router.push("/users").catch((error) => {console.log(error)})
    },
    update_avatar() {
      // You can update avatar Here
      // For reference you can check dataList example
      // We haven't integrated it here, because data isn't saved in DB
    },
    openUserCreatedDialog() {
      this.showUserCreatedPrompt = true
      // this.$vs.dialog({
      //   type: 'alert',
      //   color: 'success',
      //   title: `Usuario Creado`,
      //   text: 'Usuario creado correctamente. Los datos de acceso son: ',
      //   accept: this.showAddSuccess
      // })
    },
    showNotify(type,title,text) {
      this.$vs.notify({
        color: type || 'success',
        title: title || 'Correcto!',
        text: text || '',
        time: 8000,
        position: 'top-center',
        iconPack: 'feather',
        icon:'icon-check',
      })
    },
    showAddError(msg) {
      this.$vs.notify({
        color: 'danger',
        title: 'Error',
        text: msg || 'No se pudo agregar el usuario.',
        time: 8000,
        position: 'top-center',
        iconPack: 'feather',
        icon:'icon-alert-circle',
      })
      this.enableSaveButton()
    },
    updateProfileType(event) {
      this.data_local.profile_type = event.value
    },
    updateCardType(event) {
      this.data_local.card_type_id = event.value
    },
    updateStatus(event) {
      this.data_local.status = event.value
    }, 
    disableSaveButton() {
      this.loading = true
      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: "#saveButton",
        scale: 0.45
      })
    },
    enableSaveButton() {
      this.loading = false
      this.$vs.loading.close("#saveButton > .con-vs-loading")
    },
    closePrompt() {
      this.showUserCreatedPrompt = false
      setTimeout(() =>{
        this.$router.push("/users").catch(error => { console.log(error)})
      }, 100)
    },
    copyToClipboard() {
      this.$copyText("Email: " + this.data_local.email + " | Password: " + this.generatedPassword)
        .then(() => {
          this.showNotify("success", "Correcto!", "Se ha copiado la información.")
          this.closePrompt()
        })
        .catch(error => this.showNotify("error", "Error", "No se pudo copiar la información. " + error))
    },
    sendUserData() {
      this.showNotify(null,"Nuevo Usuario", "Credenciales enviadas por email a " + this.data_local.email)
      this.closePrompt()
    }
  },
}
</script>

<style lang="css" scoped>
  .mt-4 > label {
    font-size: 12px;
  }
</style>
