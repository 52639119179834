<template>
  <div id="page-user-add">

    <!-- <vs-alert color="danger" title="User Not Found" :active.sync="user_not_found">
      <span>User record with id: {{ $route.params.userId }} not found. </span>
      <span>
        <span>Check </span><router-link :to="{name:'page-user-list'}" class="text-inherit underline">All Users</router-link>
      </span>
    </vs-alert> -->

    <vx-card>

      <div slot="no-body" class="tabs-container px-6 pt-6">

        <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
          <vs-tab v-if="$acl.check('manager')" label="Agregar Personal" icon-pack="feather" icon="icon-user">
            <div class="tab-text">
              <add-staff-user class="mt-4" :data="user_data" />
            </div>
          </vs-tab>
          <vs-tab v-if="$acl.check('admin')" label="Agregar Administrador" icon-pack="feather" icon="icon-user">
            <div class="tab-text">
              <add-admin-user class="mt-4"/>
            </div>
          </vs-tab>
        </vs-tabs>

      </div>

    </vx-card>
  </div>
</template>

<script>
import AddStaffUser     from "./AddTabStaffAccount.vue"
import AddAdminUser     from "./AddTabAdminAccount.vue"

// Store Module
import moduleUserManagement from '@/store/user-management/moduleUserManagement.js'

export default {
  components: {
    AddStaffUser,
    AddAdminUser
  },
  data() {
    return {
      user_data: {
        name: "",
        last_name: "",
        email: "",
        profile_type: "",
        status: "",
        card_id: "",
        birthday_date: "",
        region: "1",
        id_institution: "",
        user: "",
        card_type_id: 1,
      },
      user_not_found: false,

      /*
        This property is created for fetching latest data from API when tab is changed

        Please check it's watcher
      */
      activeTab: 0,
    }
  },
  watch: {
    // activeTab() {
    //   this.fetch_user_data(this.$route.params.userId)
    // }
  },
  methods: {
    // fetch_user_data(userId) {
    //   this.$store.dispatch("userManagement/fetchUser", userId)
    //     .then(res => { this.user_data = res.data })
    //     .catch(err => {
    //       if(err.response.status === 404) {
    //         this.user_not_found = true
    //         return
    //       }
    //       console.error(err) })
    // }
  },
  created() {
    // Register Module UserManagement Module
    if(!moduleUserManagement.isRegistered) {
      this.$store.registerModule('userManagement', moduleUserManagement)
      moduleUserManagement.isRegistered = true
    }
    // this.fetch_user_data(this.$route.params.userId)
    if (!this.$acl.check("admin")) { this.user_data.id_institution = this.$store.state.AppActiveUser.institution.id; }
  }
}

</script>
